import React from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineArrowLongLeft } from 'react-icons/hi2'
import '../styles/Privacy.css'

function Privacy() {
  return (
    <div className="privacy-container">
      <nav>
        <Link to="/"><HiOutlineArrowLongLeft />Back to Home</Link>
      </nav>
      <div className="privacy-content">
        <h1>Privacy Policy</h1>
        <p>Last updated: May 2024</p>

        <section className="info-page-section">
          <h2>1. Introduction</h2>
          <p>At Stoqup, we take your privacy seriously and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services.</p>
        </section>

        <section className="info-page-section">
          <h2>2. Information We Collect</h2>
          <p>We collect and process the following categories of personal data when you use or interact with our products and services:</p>
          <ul>
            <li>Information you provide directly: name, email address, phone number, company details, and address</li>
            <li>Google account information (when you choose to sign in with Google): email address and basic profile information</li>
            <li>Usage data: how you interact with our services, device information, and IP address</li>
            <li>Business data: sales data, inventory information, and customer relationships</li>
          </ul>
        </section>

        <section className="info-page-section">
          <h2>3. How We Use Your Information</h2>
          <p>We use your information solely for providing and improving our services:</p>
          <ul>
            <li>To provide you with the core functionality of our platform</li>
            <li>To authenticate your identity and maintain your account</li>
            <li>To send important service updates and notifications</li>
            <li>To improve and optimize our services</li>
            <li>To provide customer support</li>
          </ul>
        </section>

        <section className="info-page-section">
          <h2>4. Data Sharing and Disclosure</h2>
          <p>We do not sell your personal information to third parties. We may share your information only in the following limited circumstances:</p>
          <ul>
            <li>With service providers who assist in operating our platform</li>
            <li>When required by law or to protect our legal rights</li>
            <li>With your explicit consent</li>
          </ul>
        </section>

        <section className="info-page-section">
          <h2>5. Data Protection</h2>
          <p>We implement robust security measures to protect your information:</p>
          <ul>
            <li>Industry-standard encryption for data transmission and storage</li>
            <li>Regular security assessments and updates</li>
            <li>Strict access controls and authentication procedures</li>
            <li>Continuous monitoring for potential security threats</li>
          </ul>
        </section>

        <section className="info-page-section">
          <h2>6. Data Retention and Deletion</h2>
          <p>We retain your personal information for as long as necessary to provide our services and comply with legal obligations. You may request deletion of your data by contacting us at privacy@stoqup.be. When the retention period expires, we securely delete or anonymize your data.</p>
        </section>

        <section className="info-page-section">
          <h2>7. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal information</li>
            <li>Correct inaccurate data</li>
            <li>Request deletion of your data</li>
            <li>Object to certain data processing</li>
            <li>Export your data</li>
          </ul>
        </section>

        <section className="info-page-section">
          <h2>8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
          <p>Email: hello@stoqup.eu<br />
          Address: 9000 Gent, Belgium</p>
        </section>
      </div>
    </div>
  )
}

export default Privacy 