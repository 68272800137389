import React from "react";

function DeliveryTruck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 406.783 406.783"
    >
      <g fill="#fff">
        <path
          d="M127.12 256.572c-19.742 0-35.741 15.993-35.741 35.737 0 19.745 15.999 35.738 35.741 35.738 19.749 0 35.744-15.993 35.744-35.738 0-19.744-15.995-35.737-35.744-35.737zm0 51.274c-8.582 0-15.536-6.955-15.536-15.537 0-8.586 6.954-15.537 15.536-15.537 8.583 0 15.542 6.951 15.542 15.537 0 8.582-6.959 15.537-15.542 15.537zm188.468-51.274c-19.742 0-35.74 15.993-35.74 35.737 0 19.745 15.998 35.738 35.74 35.738 19.75 0 35.744-15.993 35.744-35.738 0-19.744-15.994-35.737-35.744-35.737zm0 51.274c-8.582 0-15.535-6.955-15.535-15.537 0-8.586 6.953-15.537 15.535-15.537 8.584 0 15.543 6.951 15.543 15.537 0 8.582-6.959 15.537-15.543 15.537zM167.329 146.759c0 5.008-4.098 9.105-9.105 9.105H32.579c-5.008 0-9.104-4.097-9.104-9.105v-5.463c0-5.007 4.097-9.104 9.104-9.104h125.645c5.008 0 9.105 4.097 9.105 9.104v5.463z"
          data-original="#000000"
        ></path>
        <path
          d="M385.623 200.066c-13.105-3.407-20.604-5.549-25.75-15.487l-17.207-34.839c-5.148-9.938-18.518-18.07-29.707-18.07h-23.535s-3.166.066-3.166-3.12V99.331c0-11.327-6.41-20.595-20.045-20.595H74.405c-19.521 0-28.789 9.269-28.789 20.595v18.311s0 5.446 5.271 5.446h107.337c10.041 0 18.21 8.168 18.21 18.209v5.463c0 10.041-8.169 18.209-18.21 18.209H50.887s-5.271-.438-5.271 5.252v6.297c0 5.008 6.864 5.005 6.864 5.005h72.254c10.041 0 18.21 8.169 18.21 18.209v5.463c0 10.041-8.169 18.209-18.21 18.209H53.62s-8.004-.148-8.004 6.225v44.246c0 11.326 9.268 20.595 20.595 20.595h11.376c2.58 0 2.96-1.437 2.96-2.159 0-25.679 20.894-46.568 46.574-46.568 25.682 0 46.575 20.891 46.575 46.568 0 .725-.206 2.159 1.767 2.159h91.806c1.82 0 1.746-1.534 1.746-2.159 0-25.679 20.893-46.568 46.574-46.568s46.574 20.891 46.574 46.568c0 .725-.018 2.159 1.121 2.159h23.146c11.195 0 20.352-9.157 20.352-20.351v-38.664c.001-32.561-10.28-32.561-21.159-35.389zm-38.727-1.811h-57.928c-2.393 0-2.711-2.33-2.711-2.33V147.67s-.135-1.853 2.938-1.853h16.529c9.959 0 21.855 7.236 26.434 16.079l15.312 31a24.257 24.257 0 002.072 3.349c.544.728-.368 2.01-2.646 2.01z"
          data-original="#000000"
        ></path>
        <path
          d="M133.838 205.195c0 5.008-4.097 9.105-9.104 9.105H9.104C4.096 214.3 0 210.203 0 205.195v-5.463c0-5.007 4.097-9.104 9.104-9.104h115.63c5.008 0 9.104 4.097 9.104 9.104v5.463z"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export default DeliveryTruck;