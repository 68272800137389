import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LandingPage from "./pages/LandingPage"
import LandingPageMobile from "./pages/LandingPageMobile"
import TermsOfService from './pages/TermsOfService'
import Privacy from './pages/Privacy'

function App() {
  const isMobile = window.innerWidth < 768

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={isMobile ? <LandingPageMobile /> : <LandingPage />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
