import React from "react";

function SalesData() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 384 384"
    >
      <g fill="#fff">
        <path
          d="M10.668 234.668h42.664c5.89 0 10.668 4.773 10.668 10.664V352c0 5.89-4.777 10.668-10.668 10.668H10.668C4.778 362.668 0 357.891 0 352V245.332c0-5.89 4.777-10.664 10.668-10.664zm106.664-85.336H160c5.89 0 10.668 4.777 10.668 10.668v192c0 5.89-4.777 10.668-10.668 10.668h-42.668c-5.89 0-10.664-4.777-10.664-10.668V160c0-5.89 4.773-10.668 10.664-10.668zM224 192h42.668c5.89 0 10.664 4.777 10.664 10.668V352c0 5.89-4.773 10.668-10.664 10.668H224c-5.89 0-10.668-4.777-10.668-10.668V202.668c0-5.89 4.777-10.668 10.668-10.668zm106.668-64h42.664c5.89 0 10.668 4.777 10.668 10.668V352c0 5.89-4.777 10.668-10.668 10.668h-42.664c-5.89 0-10.668-4.777-10.668-10.668V138.668c0-5.89 4.777-10.668 10.668-10.668zm0 0"
          data-original="#000000"
        ></path>
        <path
          d="M352 0c-17.664.02-31.98 14.336-32 32 .043 3.484.66 6.938 1.828 10.219L268.43 73.96a31.728 31.728 0 00-51.082 6.84L170.25 57.473c.23-1.371.367-2.754.418-4.141a32 32 0 00-54.621-22.695 31.99 31.99 0 00-6.938 34.894l-61.093 45.594A31.63 31.63 0 0032 106.668c-17.672 0-32 14.324-32 32 0 17.672 14.328 32 32 32s32-14.328 32-32a31.755 31.755 0 00-2.145-11.25l61.704-46.05c12.07 6.73 27.125 4.757 37.05-4.86l53.559 26.527c3.57 15.903 18.535 26.574 34.734 24.77 16.2-1.805 28.45-15.504 28.43-31.805 0-.813-.18-1.578-.238-2.375l58.078-34.527A31.681 31.681 0 00352 64c17.672 0 32-14.328 32-32S369.672 0 352 0zm0 0"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export default SalesData;